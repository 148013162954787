.card-style{
  background-color: #004987 !important;
  height: 100% !important;
  justify-content: center !important;
  overflow: hidden !important;
}
.icons{
 font-size: 6rem !important;
 margin-top: 10% !important;
}
.righ-text{
 font-size: 20rem !important;
}
.div
{
z-index: 0 !important;  
position: absolute !important;
height: 100% !important;
width: 101% !important;
right: -1% !important;
left: -1% !important;
overflow: hidden !important;
}
.link {
text-align: left;
font-size: 1rem !important ; 
background: none;
margin: 0;
padding: 0;
border: none;
color:#FFFFFF !important;
cursor: pointer;
text-transform: lowercase !important;
text-decoration: underline !important;
}
.link:hover{
color:#FFFFFF !important;
}

