.rightBack {
  width: 100% !important;
}

.paddingContainer {
  padding-right:2vw  !important;
}

.dividerCenterCss {
  margin-top: 17% !important;
  font-size: initial !important;
}

.futureEnhacement,.displayPrint{
  display: none !important;
}

.printSize{
  width: 100% !important;
  height: 100% !important;
  padding: 10% !important;
}

.dialogImagePadding{
  padding: 0% !important;
}

.primaryBack {
  width: -webkit-fill-available !important;
  min-height: 40vh;
}
 
.stack-top {
  position: absolute;
  top: 20%;
  right: 7%;
  left: 7%;
}
.rightSecondaryBack {
  width: -webkit-fill-available !important;
  position: absolute;
  height: -webkit-fill-available !important;
}

.landingHeight{
  height: 110% !important;
}

.serviceHeight{
  height: 165% !important;
}

.cardPadding{
  padding:1.5% 3% !important;
}
