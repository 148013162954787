.app-logo {
  max-width: 150px !important;
  height: 100%;
  width:250% !important
}

.borderRight{
  border-right: none !important;
}

@media only screen and (max-width: 500px) {
  .app-logo{
    width:120% !important
  }
}
