.boxTab{
    box-shadow: none !important;
}

.width100{
    max-width: 100% !important;
}

.paddingDialog{
  padding:0.5% 0% !important
}

.timeDateItalic{
    font-style: italic;
}

.marginDialogTitle{
    margin-right: 16% !important;
}

.textTransform{
  text-transform: none !important;
  font-size: 110% !important;
}

.fontBold{
  font-weight: bold !important;
}

@page {
    size: "A4";
    margin: 27mm 16mm 27mm 16mm;
  }

  @media print {
    html, body {
      width: 210mm;
      height: 297mm;
    }
  }