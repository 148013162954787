.bodyimage {
  background: url(../../assests/qc-bg.jpg) no-repeat center center;
  padding: 0%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 50%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  object-fit: cover;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.italicTypography{
  font-style: italic;
}

.otpInputStyle {
  width: 3vw !important;
  height: 8vh !important;
  margin: 25% 1vh;
  font-size: 2rem;
  border: 2px solid rgba(135, 206, 250, 0.6) !important;
  text-align: center;
  background-color: #0f2555;
  color: white;
}

.split {
  width: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.right {
  right: 0;
  overflow-y: auto !important;
  overflow-x: hidden;
}

.tabletImage {
  width: 100% !important;
  margin-top:140px !important
}


.form {
  width: 100%;
  margin-top: 4vw;
}

.containermargin {
  margin: 15vh 4.5vw 5vh 4.5vw;
}

.leftBorder {
  border-radius: 19px 0 0 19px !important;
}

.rightBorder {
  border-radius: 0 19px 19px 0 !important;
}

*:focus {
  outline: none !important;
}

.btnBorder {
  border-radius: 20px !important;
  width: 120px;
}

.lblForget {
  text-transform: lowercase !important;
}

.lblForget:hover {
  text-decoration: none !important;
  color: white !important;
}

.btnLink:hover {
  color: white !important;
  text-decoration: none;
}

.link:hover {
  text-decoration: none !important;
}
.link{
  text-decoration: none !important;
}

.btnWidth {
  width: 120px;
  box-shadow: none !important;
  text-transform: initial !important;
  font-size: 1.4rem !important;
  padding: 1% !important;
  height: 100% !important;
}

.btnBorder {
  border-radius: 20px !important;
  width: 140px;
  text-transform: initial !important;
  font-size: 1.3rem !important;
  padding: 2% !important;
}

.helpTextMargin {
  padding: 0 6vw 1vh 6vw !important;
}

@media only screen and (max-width: 1024px) {
  .otpInputStyle {
    width: 5vw !important;
    height:auto !important;
    margin: 25% 0.7vw;
  }
  @media only screen and (min-height: 1000px) {
    .otpInputStyle {
      height:auto !important;
      font-size: 1.5rem;
    }
  }
}


/* =-------------------------------------------------------------------------------------= */
@media only screen and (max-width: 500px) {

  .containermargin {
    margin-top: 5vh;
    padding-bottom: 6vh;
    margin-bottom:0 !important;

  }

  .otpInputStyle {
    width: 13vw !important;
    height:auto !important;
    font-size: 1.5rem;
  }
  .btnWidth {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 450px) {
  .btnWidth {
    width: 100px;
  }
}
@media only screen and (max-width: 330px) {

  .btnBorder {
    width: 100px;
  }
}


@media only screen and (min-width: 2000px) and (min-height:1500px) {
  .otpInputStyle {
    width: 5vw !important;
    font-size: 4rem !important;
  }
}

.noWrapRadio{
  flex-wrap: nowrap !important;
}

svg{
  vertical-align: top  !important;
}
:root{
  --PhoneInputCountrySelectArrow-color: black !important;
  --PhoneInputCountrySelectArrow-width:0.4em !important
}
.phoneClass{
  margin-top: 3vh !important;
  margin-bottom: 1vh;
}
.phoneClass .PhoneInputInput{
  border:none;
  background-color: initial;
  border-bottom: 1px solid white;
  color: white;
  font-size: initial;
}
.phoneClass .PhoneInputInput:focus{
  border-bottom-width: 2px ;
}
.phoneClass .PhoneInputCountrySelectArrow{
  border-color: white;
}