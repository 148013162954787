.horizontalLine {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .horizontalLine::after {
    content: "";
    flex: 1;
    border: 1px solid rgba(0,0,0,.1);
  }
  .horizontalLine::after {
    margin-left: 1em;
  }
  
  .wordWrap{
    overflow-wrap: anywhere;
  }
  
  .marginleftError{
    margin-left: 4% !important;
  }
  
  .minWidthName{
    min-width: 32% !important;
  }

  #rregisteredCell,#registeredCell_Id{
    padding : 7px 1vw;
    border-radius : 4px;
    border-color : lightgrey;
    border-width : thin;
  }