.linkText {
  color: #7ccdef !important;
}

.smallLabel {
  font-size: 14px !important;
  color: #b4c9d5 !important;
}
.card {
  min-height: 210px !important;
  border-radius: 12px !important;
  padding: 20px !important;
  background-color: #ffffff !important;
}

.margin-0 {
  margin: 0px;
}

.margin-10 {
  margin: 10px;
}

.margin-20 {
  margin: 20px;
}

.margin-30 {
  margin: 30px;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-55 {
  margin-top: 55px;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-50 {
  margin-right: 50px;
}

.padding-0 {
  padding: 0px;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-50 {
  padding-right: 50px;
}

.float-left {
  float: left;
}

.clearboth {
  clear: both;
}

.width100 {
  width: 100%;
}
@keyframes blinker {
  50% {
      opacity: 0;
  }
}